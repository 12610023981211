export let settings = {
    gravity: 0.2,
    friction: 0.3,
    density: 300,
    trail: false,
    stroke: false
};
export let mouse = {
    x: undefined,
    y: undefined
};

export const mouseHandler = (e) => {
    const {x, y} = e;
    mouse = { x, y };
};

export const canvas = document.querySelector('canvas');
export const c = canvas.getContext('2d');



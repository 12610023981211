import { randomInt } from './methods';
import { canvas, settings, mouse } from './variables';

export default class Ball {
    constructor(x, y, dx, dy, radius, color, context){
        this.x = x;
        this.y = y;
        this.dx = dx;
        this.dy = dy;
        this.radius = radius;
        this.color = color;
        this.context = context;
    }

    update() {
        if (this.y + this.radius + this.dy > canvas.height || this.y - this.radius + this.dy <= 0) {
            this.dy = -this.dy;
            this.dy = this.dy * (1 - settings.friction);
            this.dx = this.dx * (1 - settings.friction);
        }else {
            this.dy += settings.gravity;
        }

        if (this.x + this.radius >= canvas.width || this.x - this.radius <= 0) {
            this.dx = -this.dx * (1 - settings.friction);
        }

        this.x += this.dx;
        this.y += this.dy;

        if (mouse.x - this.x < 50 && mouse.x - this.x > -50 &&
            mouse.y - this.y < 50 && mouse.y - this.y > -50 && this.radius < 50) {
            this.radius += 5
        } else if (this.radius > 20){
            this.radius -= 1;
        }
        this.draw();
    };

    push() {
        if (this.dy <= 0.5){
            this.dy = 3;
        }
        this.dy = -Math.abs(this.dy);
        this.dy += randomInt(-7, -0.5);
        this.dx += randomInt(-1, 1);
    }

    draw() {
        this.context.beginPath();
        this.context.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
        this.context.fillStyle = this.color;
        this.context.fill();
        if(settings.stroke){
            this.context.stroke();
        }
        this.context.closePath();
    };
}

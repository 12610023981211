import dat from 'dat.gui';
import Ball from './Ball';
import { randomInt, randomColor } from './methods';
import { canvas, settings, c, mouseHandler } from './variables';
import { TweenLite } from 'gsap';
// document.addEventListener('DOMContentLoaded', init);

let ballArray = [];
let w = window.innerWidth;
let h = window.innerHeight;

const resizeCanvas = () => {
    w = window.innerWidth;
    h = window.innerHeight;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;
};

/**
 *
 * @param context
 */
function initBalls(context){
    ballArray = [];
    for (let i = 0; i < settings.density; i++) {
        const radius = randomInt(8, 20);
        const x = randomInt(radius, canvas.width - radius);
        const y = randomInt(radius, canvas.height - radius);
        const dx = randomInt(-3, 3);
        const dy = randomInt(-2, 2);
        ballArray.push(new Ball(x, y, dx, dy, radius, randomColor(), context));
    }
}

function pushBalls(){
    for (let i = 0; i < ballArray.length; i++) {
        ballArray[i].push();
    }
}

/**
 *
 * @param context
 */
function initDatGui(context) {
    const gui = new dat.GUI();

    let folder1 = gui.addFolder('Settings');

    let gravitySetting  = folder1.add(settings, 'gravity', 0, 1).step(0.1).name('Gravity');
    let frictionSetting = folder1.add(settings, 'friction', 0, 1).step(0.1).name('Friction');
    let densitySetting = folder1.add(settings, 'density', 100, 1000).step(100).name('Density');
    let trailSetting = folder1.add(settings, 'trail').name('Trail');
    let strokeSetting = folder1.add(settings, 'stroke').name('Stroke');

    densitySetting.onChange(function(){
        initBalls(context);
    });

    folder1.open();
}


const startBtn = document.querySelector('.start-btn');

startBtn.addEventListener('click', function() {
    const ctx = new AudioContext();
    init(ctx);
    closeModal();
});

function closeModal() {
    const modal = document.querySelector('.start-modal');
    TweenLite.to(modal, .5, {
        y: '-100%'
    })
}


function init(audioCtx) {
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    initDatGui(c);
    initBalls(c);

    // Music init
    const audio = document.querySelector("audio");
    audio.play();
    const analyser = audioCtx.createAnalyser();
    const source = audioCtx.createMediaElementSource(audio);
    source.connect(analyser);
    analyser.connect(audioCtx.destination);
    analyser.fftSize = 256;
    let frequencyData = new Uint8Array(analyser.frequencyBinCount);

    function drawLoop(){
        if(!settings.trail){
            c.clearRect(0, 0, w, h);
        }

        analyser.getByteFrequencyData(frequencyData);
        for (let i = 0; i < ballArray.length; i++) {
            ballArray[i].update();
        }

        for(let d in frequencyData) {
            let f = frequencyData[d];
            if(1 < d < 3 && f > 250){
                pushBalls();
            }
        }

        window.requestAnimationFrame(drawLoop);
    }

    const btn = document.querySelector('.push-btn');
    btn.addEventListener('click', pushBalls);

    window.addEventListener('resize', resizeCanvas);
    canvas.addEventListener('mousemove', mouseHandler);

    window.requestAnimationFrame(drawLoop);
}

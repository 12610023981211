/**
 *
 * @param min
 * @param max
 * @returns {number}
 */
export  const randomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};

/**
 *
 * @returns {string}
 */
export const randomColor = () => {
    return '#'+(0x1000000+(Math.random())*0xffffff).toString(16).substr(1,6);
};


